import * as AvatarPrimitive from '@radix-ui/react-avatar';
import { Link } from '@remix-run/react';
import * as React from 'react';
import { cn } from '~/common/utils/styles';

const AvatarRoot = React.forwardRef<
    React.ElementRef<typeof AvatarPrimitive.Root>,
    React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Root>
>(({ className, ...props }, ref) => (
    <AvatarPrimitive.Root
        ref={ref}
        className={cn(
            'relative flex shrink-0 overflow-hidden rounded-full',
            className
        )}
        {...props}
    />
));
AvatarRoot.displayName = AvatarPrimitive.Root.displayName;

const AvatarImage = React.forwardRef<
    React.ElementRef<typeof AvatarPrimitive.Image>,
    React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Image>
>(({ className, ...props }, ref) => (
    <AvatarPrimitive.Image
        ref={ref}
        className={cn('aspect-square h-full w-full object-cover', className)}
        {...props}
    />
));
AvatarImage.displayName = AvatarPrimitive.Image.displayName;

const AvatarFallback = React.forwardRef<
    React.ElementRef<typeof AvatarPrimitive.Fallback>,
    React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Fallback>
>(({ className, ...props }, ref) => (
    <AvatarPrimitive.Fallback
        ref={ref}
        className={cn(
            'flex h-full w-full items-center justify-center rounded-full bg-slate-100 dark:bg-slate-700',
            className
        )}
        {...props}
    />
));
AvatarFallback.displayName = AvatarPrimitive.Fallback.displayName;

const avatarVariants = {
    xxs: 'h-7 w-7',
    xs: 'h-9 w-9',
    sm: 'h-10 w-10',
    md: 'h-11 w-11',
    lg: 'h-12 w-12',
    xl: 'h-14 w-14',
    xxl: 'h-16 w-16',
};

export const Avatar = ({
    href,
    src,
    variant,
    ...rest
}: React.HTMLAttributes<HTMLSpanElement> & {
    href?: string;
    src?: string | null | undefined;
    variant?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
}) => {
    const variantClassName = variant
        ? avatarVariants[variant]
        : avatarVariants.md;

    const component = (
        <AvatarRoot className={variantClassName} {...rest}>
            <AvatarImage src={src || '/images/avatar/user.svg'} />
            <AvatarFallback>
                <AvatarImage src="/images/avatar/user.svg" />
            </AvatarFallback>
        </AvatarRoot>
    );

    return href ? (
        <Link className={variantClassName} to={href}>
            {component}
        </Link>
    ) : (
        component
    );
};
